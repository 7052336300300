import React, {useState, useEffect} from 'react'
import {useParams} from 'react-router-dom'
import styled from 'styled-components/macro'
import GolfCart from '../../assets/images/GolfCart.svg'
import GolfBall from '../../assets/images/GolfBall.svg'
import Golfer from '../../assets/images/Golfer.svg'
import {Panel, RegistrationButton} from '../../assets/elements'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'

const Icon = styled.img`
  height: 70px;
  @media (min-width: 1000px) {
    height: 100px;
  }
`

const ItemDescription = styled.div`
  padding: 0 10px;
  white-space: pre-wrap;
`

const IconAndDescription = styled.div`
  display: flex;
  max-width: 640px;
  img {
    margin-bottom: 5px;
  }
`

const PlayerPanel = styled(Panel)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media (min-width: 1000px) {
    flex-direction: row;
    justify-content: space-between;
  }
`

const ButtonText = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`

const Price = styled.span`
  font-weight: 700;
  font-size: 1.2rem;
  padding-left: 2px;
  @media (min-width: 1000px) {
    padding-left: 0;
  }
`

const CloseButton = styled.button`
  display: flex;
  justify-content: center;
  padding: 0.3rem;
  width: 2em;
  height: 2em;
  margin-right: 5px;
`
const CloseButtonAndName = styled.span`
  display: flex;
`

const nextFee = (
  playerCount,
  playerFee = 0,
  twosomeDiscount = 0,
  foursomeDiscount = 0,
) => {
  let fee = playerFee
  if ((playerCount + 1) % 4 === 0) {
    fee = playerFee - (foursomeDiscount || 0) + twosomeDiscount
  } else if ((playerCount + 1) % 2 === 0) {
    fee = playerFee - (twosomeDiscount || 0)
  }
  return fee
}
function PlayerItem(props) {
  const {id} = useParams()
  const [nextPlayerFee, setNextPlayerFee] = useState(0)
  useEffect(() => {
    const fee = nextFee(
      props.playerCount,
      props.option.playerFee || 0,
      props.option.twosomeDiscount,
      props.option.foursomeDiscount,
    )
    setNextPlayerFee(fee)
  }, [
    props.playerCount,
    props.option.playerFee,
    props.option.twosomeDiscount,
    props.option.foursomeDiscount,
  ])

  let addText = 'Add '
  if (props.option.quantityInCart && props.option.quantityInCart > 0)
    addText += 'Another '
  if (props.option.soldAs && props.option.soldAs > 1) {
    switch (props.option.soldAs) {
      case 2:
        addText += 'Twosome'
        break
      case 3:
        addText += 'Threesome'
        break
      case 4:
        addText += 'Foursome'
        break
      default:
        break
    }
  } else {
    addText += 'Player'
  }

  let status = 'open'
  let available = 0
  //get available from round or event
  if (props.event.multipleRounds) {
    props.event.rounds.forEach(round => {
      if (round && props.option && round.roundID === props.option.roundID) {
        available = Number(round.available) - Number(round.quantityInCart)
      }
    })
  } else {
    available =
      Number(props.event.playersAvailable || 0) -
      Number(props.totalPlayerCount || 0)
  }

  let quantityInCart = Number(props.option.quantityInCart || 0)
  let soldAs = Number(props.option.soldAs) || 1

  if (!id) {
    if ((available < 1 || soldAs > available) && quantityInCart === 0) {
      status = 'soldout'
      addText = 'Sold Out'
    } else if (quantityInCart > 0 && available < 1) {
      status = 'nomore'
    }
  }

  return (
    <PlayerPanel>
      <IconAndDescription>
        {(!props.option.icon || props.option.icon === 'GolfCart') && (
          <Icon src={GolfCart} />
        )}
        {props.option.icon === 'Golfer' && <Icon src={Golfer} />}
        {props.option.icon === 'GolfBall' && <Icon src={GolfBall} />}
        <ItemDescription>
          <CloseButtonAndName>
            {quantityInCart > 0 && soldAs > 1 && (
              <CloseButton
                type="button"
                className="btn btn-sm btn-secondary"
                data-optionid={props.option ? props.option.id : ''}
                data-cy="deletePlayerGroupButton"
                onClick={props.removeGroupClick}
              >
                <FontAwesomeIcon icon="times-circle" />
              </CloseButton>
            )}
            <h5>{props.option.playerOptionName}</h5>
          </CloseButtonAndName>
          <h6 data-testid="registration-description">
            {props.option.playerRegistrationDescription}
          </h6>
        </ItemDescription>
      </IconAndDescription>

      <RegistrationButton
        type="button"
        onClick={props.addPlayerClick}
        disabled={status !== 'open'}
        data-testid="add-player-button"
        data-optionindex={props.optionIndex}
      >
        <ButtonText>
          <span>
            <div>{addText}</div>
            {status === 'open' && (
              <Price>
                $
                {Number(nextPlayerFee).toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </Price>
            )}
          </span>
          {status !== 'soldout' && <div>{`${quantityInCart} In Cart`} </div>}
        </ButtonText>
      </RegistrationButton>
    </PlayerPanel>
  )
}

export default PlayerItem
