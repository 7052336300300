import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import 'core-js/features/array/find'
import 'core-js/features/array/includes'
import 'core-js/features/symbol'
import 'core-js/features/symbol/async-iterator'
import 'core-js/features/number/is-nan'
import 'core-js/features/string/repeat'
import 'core-js/features/string/includes'
import 'core-js/features/object/keys'
import 'regenerator-runtime/runtime'

const rewritePattern = require('regexpu-core')
const {
  generateRegexpuOptions,
} = require('@babel/helper-create-regexp-features-plugin/lib/util')

const {RegExp} = global
try {
  new RegExp('a', 'u')
} catch (err) {
  global.RegExp = function(pattern, flags) {
    if (flags && flags.includes('u')) {
      return new RegExp(
        rewritePattern(
          pattern,
          flags,
          generateRegexpuOptions({flags, pattern}),
        ),
      )
    }
    return new RegExp(pattern, flags)
  }
  global.RegExp.prototype = RegExp
}
